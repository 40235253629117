@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

html, body {
  font-size: 16px;
  color: #FFF;
  font-family: 'Roboto Condensed' !important;
  font-weight: 400;
}

.App {
  background-image: url("/public/photos/background.jpg");
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.7);
  height: 100vh;
  width: 100vw;
  font-size: 16px;
  color: #FFF;
  overflow-y: scroll;
}

.about > .appContainer { display: block !important; }

.appContainer {
    width: 80vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.astrophotography { background-image: url("/public/photos/astroBackground.png") !important; }
